import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { faArrowRight, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, Drawer, FileState, Modal } from '@skiwo/components';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { ApiError } from '../../../Api';
import {
  useCancelJobMutation,
  useCreateJobMutation,
  useUpdateJobMutation,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useGetFilesQueries } from '../../../Api/Endpoints/System/System.hooks';
import { InformationSection } from '../../../CreateInterpretationOrder/InformationSection/InformationSection';
import {
  CreateInterpretationOrderFormValues,
  createInterpretationOrderSchema,
} from '../../../CreateInterpretationOrder/schema';
import SettingsSection from '../../../CreateInterpretationOrder/SettingsSection/SettingsSection';
import {
  getCreateInterpretationFormInitialValues,
  getInformationOrderData,
} from '../../../CreateInterpretationOrder/utils';
import getCreateInterpretationOrderFormData from '../../../CreateInterpretationOrder/utils/getCreateInterpretationOrderFormData';
import appendFormAttachmentsBlob from '../../../helpers/appendFormAttachmentsBlob';
import { appendNestedFormObjectWithEmptyValues } from '../../../helpers/appendNestedFormDataObject';
import { getDefaultReferences } from '../../../helpers/getDefaultReferences';
import routes from '../../../helpers/routes';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useQualifications } from '../../../providers/QualificationsProvider';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobCancellationReason,
  ManagerJobDetails,
  ManagerJobStatus,
} from '../../../types/ManagerJob';
import { getSessionTypeOptions } from '../../utils/getSessionTypeOptions';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import { getMergedAttachmentsWithDeletedFlag } from './getMergedAttachmentsWithDeletedFlag';
import styles from './EditInformationFormDrawer.module.scss';

const getFormattedDate = (date: Date) => format(date, 'dd.MM.yyyy,');

interface EditInformationFormDrawerProps {
  job: ManagerJobDetails;
}

export default function EditInformationFormDrawer({ job }: EditInformationFormDrawerProps) {
  const [isCategoryRequired, setIsCategoryRequired] = useState(false);
  const [isCategorySubjectRequired, setIsCategorySubjectRequired] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [isRebookingConfirmationSelected, setIsRebookingConfirmationSelected] = useState(false);
  const { showToast, showErrorToast } = useToast();
  const { qualifications } = useQualifications();
  const { languages, getLanguageById, getDefaultLanguage } = useLanguages();
  const intl = useIntl();
  const navigate = useNavigate();
  const updateJob = useUpdateJobMutation();
  const cancelJob = useCancelJobMutation();
  const createJob = useCreateJobMutation();
  const queries = useGetFilesQueries(job.attachments ?? []);

  const initialAttachments = queries
    .filter((query) => !!query.data)
    .map((query) => query.data) as FileState[];
  const { isDrawerOpen, closeDrawer } = useJobDetailsDrawer();

  const isDrawerOpened = isDrawerOpen(JobDetailsDrawerName.EditInformationDrawer);
  const handleCloseDrawer = () => closeDrawer(JobDetailsDrawerName.EditInformationDrawer);

  const hasDangerousFieldsWarningVisible = [
    ManagerJobStatus.Accepted,
    ManagerJobStatus.InProgress,
    ManagerJobStatus.Finished,
  ].includes(job.status);

  const schema = createInterpretationOrderSchema({
    intl,
    isCategoryRequired,
    isCategorySubjectRequired,
  });

  const handleCloseModal = () => {
    setIsReplacementModalOpen(false);
    setIsRebookingConfirmationSelected(false);
  };

  return (
    <Drawer
      title={intl.formatMessage({
        id: translationKeys.job_edit_information_drawer_title,
      })}
      show={isDrawerOpened}
      onClose={handleCloseDrawer}
    >
      <Formik<CreateInterpretationOrderFormValues>
        key={initialAttachments.length || job.id}
        validationSchema={schema}
        initialValues={getCreateInterpretationFormInitialValues(languages, job, initialAttachments)}
        onSubmit={async (values) => {
          const createOrderData = getInformationOrderData(values, qualifications);
          const createOrderFormData = new FormData();

          appendNestedFormObjectWithEmptyValues(createOrderFormData, createOrderData);

          const formAttachments = getMergedAttachmentsWithDeletedFlag(
            initialAttachments,
            values.attachments,
          );

          appendFormAttachmentsBlob(createOrderFormData, formAttachments);

          updateJob.mutate(
            { id: job.id, body: createOrderFormData },
            {
              onSuccess: () => {
                handleCloseDrawer();
                showToast({
                  variant: 'success',
                  message: intl.formatMessage({
                    id: translationKeys.job_edit_information_updated_successfully,
                  }),
                });
              },
              onError: (error) => {
                showErrorToast(error);
              },
            },
          );
        }}
      >
        {(formikProps) => {
          const hasLanguageChanged =
            formikProps.initialValues.languageId !== formikProps.values.languageId;

          const changedTimes = formikProps.initialValues.dates.reduce<
            { from: string; to: string }[]
          >((prev, current, index) => {
            if (
              current.date.valueOf() !== formikProps.values.dates[index].date.valueOf() ||
              current.startTime?.label !== formikProps.values.dates[index].startTime?.label ||
              current.finishTime?.label !== formikProps.values.dates[index].finishTime?.label
            ) {
              prev.push({
                from: `${getFormattedDate(current.date)} ${current.startTime?.label} - ${
                  current.finishTime?.label
                }`,
                to: `${getFormattedDate(formikProps.values.dates[index].date)} ${
                  formikProps.values.dates[index].startTime?.label
                } - ${formikProps.values.dates[index].finishTime?.label}`,
              });
            }

            return prev;
          }, []);

          const hasTimeChanged = changedTimes.length > 0;

          const hasSessionTypeChanged =
            formikProps.initialValues.sessionType !== formikProps.values.sessionType;

          const hasAddressChanged =
            formikProps.initialValues.contactPersonAddress?.label !==
            formikProps.values.contactPersonAddress?.label;

          const hasDangerousFieldEdited =
            hasLanguageChanged || hasTimeChanged || hasSessionTypeChanged || hasAddressChanged;

          return (
            <>
              <Form className={styles.form}>
                <InformationSection
                  formikProps={formikProps}
                  enterpriseId={job.enterprise?.id}
                  setIsCategoryRequired={setIsCategoryRequired}
                  setIsCategorySubjectRequired={setIsCategorySubjectRequired}
                  hasDangerousFieldsDisabled={job.status === ManagerJobStatus.Finished}
                  hasDangerousFieldsWarningVisible={hasDangerousFieldsWarningVisible}
                  isEditForm
                />
                <SettingsSection
                  formikProps={formikProps}
                  customer={{ emailVerified: false }}
                  isEditForm
                />
                <footer className={styles.formFooter}>
                  <Button size="x-large" variant="white" onClick={handleCloseDrawer}>
                    {intl.formatMessage({
                      id: translationKeys.job_edit_information_drawer_cancel_label,
                    })}
                  </Button>
                  {hasDangerousFieldsWarningVisible && hasDangerousFieldEdited ? (
                    <Button
                      size="x-large"
                      type="button"
                      onClick={async () => {
                        formikProps.validateForm().then((formikErrors) => {
                          const errorKeys = Object.keys(formikErrors);

                          if (errorKeys.length) {
                            formikProps.setTouched(
                              errorKeys.reduce<Record<string, boolean>>((acc, key) => {
                                acc[key] = true;
                                return acc;
                              }, {}),
                            );
                          } else {
                            setIsReplacementModalOpen(true);
                          }
                        });
                      }}
                    >
                      {intl.formatMessage({
                        id: translationKeys.job_edit_information_drawer_submit_label,
                      })}
                    </Button>
                  ) : (
                    <Button size="x-large" type="submit" isLoading={updateJob.isPending}>
                      {intl.formatMessage({
                        id: translationKeys.job_edit_information_drawer_submit_label,
                      })}
                    </Button>
                  )}
                </footer>
              </Form>
              <Modal
                title={intl.formatMessage({
                  id: translationKeys.job_edit_information_drawer_rebooking_modal_title,
                })}
                description={intl.formatMessage({
                  id: translationKeys.job_edit_information_drawer_rebooking_modal_description,
                })}
                show={isReplacementModalOpen}
                onHide={handleCloseModal}
                onCancel={handleCloseModal}
                submitButtonText={intl.formatMessage({
                  id: translationKeys.job_edit_information_drawer_rebooking_modal_submit_label,
                })}
                isSubmitDisabled={!isRebookingConfirmationSelected}
                isSubmitting={cancelJob.isPending || createJob.isPending}
                submitButtonVariant="danger"
                onSubmit={async () => {
                  try {
                    await cancelJob.mutateAsync({
                      id: job.id,
                      reason: ManagerJobCancellationReason.DangerousFieldsEdited,
                      other: false,
                      sendEmail: false,
                    });
                  } catch (error) {
                    return showErrorToast(error as ApiError);
                  }

                  const {
                    defaultReference: defaultBookingReference,
                    defaultReferenceFrom: defaultBookingReferenceFrom,
                  } = getDefaultReferences({
                    allowReference: job.referenceSettings.allowBookingReference,
                    customerDefaultReference: job.info.bookingReference,
                    departmentDefaultReference: job.department?.defaultBookingReference,
                    enterpriseDefaultReference: job.enterprise?.defaultBookingReference,
                  });

                  const {
                    defaultReference: defaultPaymentBookingReference,
                    defaultReferenceFrom: defaultPaymentBookingReferenceFrom,
                  } = getDefaultReferences({
                    allowReference: job.referenceSettings.allowPaymentBookingReference,
                    customerDefaultReference: job.info.paymentBookingReference,
                    departmentDefaultReference: job.department?.defaultPaymentBookingReference,
                    enterpriseDefaultReference: job.enterprise?.defaultPaymentBookingReference,
                  });

                  const formData = getCreateInterpretationOrderFormData({
                    qualifications,
                    values: formikProps.values,
                    defaultLanguageId: getDefaultLanguage()?.id,
                    customerUid: job.owner?.person?.uid,
                    defaultBookingReference,
                    defaultBookingReferenceFrom,
                    defaultPaymentBookingReference,
                    defaultPaymentBookingReferenceFrom,
                  });

                  createJob.mutate(formData, {
                    onSuccess: ({ data }) => {
                      handleCloseModal();
                      handleCloseDrawer();
                      showToast({
                        variant: 'success',
                        message: intl.formatMessage({
                          id: translationKeys.job_edit_information_drawer_rebooking_modal_assignment_reboked_successfully,
                        }),
                      });
                      if (data?.id) {
                        navigate(`${routes.jobs}/${data.id}`);
                      }
                    },
                    onError: (error) => {
                      showErrorToast(error);
                    },
                  });
                }}
              >
                <div className={styles.modalBody}>
                  <div className={styles.modalGrid}>
                    {hasLanguageChanged && (
                      <GridRow
                        label={intl.formatMessage({
                          id: translationKeys.job_edit_information_drawer_rebooking_modal_language_label,
                        })}
                        body={[
                          {
                            from: getLanguageById(parseInt(formikProps.initialValues.languageId)),
                            to: getLanguageById(parseInt(formikProps.values.languageId)),
                          },
                        ]}
                      />
                    )}
                    {hasTimeChanged && (
                      <GridRow
                        label={intl.formatMessage({
                          id: translationKeys.job_edit_information_drawer_rebooking_modal_time_label,
                        })}
                        body={changedTimes}
                      />
                    )}
                    {hasSessionTypeChanged && (
                      <GridRow
                        label={intl.formatMessage({
                          id: translationKeys.job_edit_information_drawer_rebooking_modal_session_type_label,
                        })}
                        body={[
                          {
                            from: getSessionTypeOptions(intl).find(
                              (sess) => sess.key === formikProps.initialValues.sessionType,
                            )?.label,
                            to: getSessionTypeOptions(intl).find(
                              (sess) => sess.key === formikProps.values.sessionType,
                            )?.label,
                          },
                        ]}
                      />
                    )}
                    {hasAddressChanged && (
                      <GridRow
                        label={intl.formatMessage({
                          id: translationKeys.job_edit_information_drawer_rebooking_modal_address_label,
                        })}
                        body={[
                          {
                            from: formikProps.initialValues.contactPersonAddress?.label,
                            to: formikProps.values.contactPersonAddress?.label,
                          },
                        ]}
                      />
                    )}
                  </div>
                  <Banner
                    variant="information"
                    text={intl.formatMessage({
                      id: translationKeys.job_edit_information_drawer_rebooking_modal_info_banner,
                    })}
                  />
                  <B_Form.Check
                    className={styles.modalCheckbox}
                    id="rebookingConfirmation"
                    type="checkbox"
                    label={intl.formatMessage({
                      id: translationKeys.job_edit_information_drawer_rebooking_modal_confirmation_label,
                    })}
                    checked={isRebookingConfirmationSelected}
                    onChange={(e) => setIsRebookingConfirmationSelected(e.target.checked)}
                  />
                </div>
              </Modal>
            </>
          );
        }}
      </Formik>
    </Drawer>
  );
}

const GridRow = ({ label, body }: { label: string; body: { from?: string; to?: string }[] }) => (
  <>
    <div>
      <FontAwesomeIcon icon={faExclamationCircle} className={styles.iconWarning} /> {label}
    </div>
    <div>
      {body.map(({ from, to }) => (
        <div key={from} className={styles.bodyCell}>
          {from} <FontAwesomeIcon icon={faArrowRight} /> {to}
        </div>
      ))}
    </div>
  </>
);
