import React from 'react';
import { IntlShape } from 'react-intl';
import { faCircleCheck, faCirclePause, faCirclePlay, faUserSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItem } from '@skiwo/components';
import { format } from 'date-fns';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobInviteStatus } from '../../../types';

export const getFormattedDate = (date: string): string =>
  format(new Date(date), 'dd MMM yyyy, HH:mm');

export const getAutoInviteProps = (
  status: ManagerJobInviteStatus | null,
  intl: IntlShape,
): { tooltip: string; icon?: React.ReactNode } | undefined => {
  switch (status) {
    case ManagerJobInviteStatus.NoSuitableInterpreters:
      return {
        tooltip: intl.formatMessage({
          id: translationKeys.job_interpreters_section_auto_invite_no_suitable_interpreters_tooltip,
        }),
        icon: <FontAwesomeIcon icon={faUserSlash} />,
      };
    case ManagerJobInviteStatus.Disabled:
      return {
        tooltip: intl.formatMessage({
          id: translationKeys.job_interpreters_section_auto_invite_disabled_tooltip,
        }),
      };
    case ManagerJobInviteStatus.Finished:
      return {
        tooltip: intl.formatMessage({
          id: translationKeys.job_interpreters_section_auto_invite_finished_tooltip,
        }),
        icon: <FontAwesomeIcon icon={faCircleCheck} />,
      };
    case ManagerJobInviteStatus.Paused:
      return {
        tooltip: intl.formatMessage({
          id: translationKeys.job_interpreters_section_auto_invite_paused_tooltip,
        }),
        icon: <FontAwesomeIcon icon={faCirclePause} />,
      };
    case ManagerJobInviteStatus.Ongoing:
      return {
        tooltip: intl.formatMessage({
          id: translationKeys.job_interpreters_section_auto_invite_in_progress_tooltip,
        }),
        icon: <FontAwesomeIcon icon={faCirclePlay} />,
      };
    default:
      return undefined;
  }
};

export const getTimeDropdownItem =
  (intl: IntlShape) =>
  (time: number): DropdownItem => ({
    id: time.toString(),
    text: intl.formatMessage(
      {
        id: translationKeys.job_interpreters_section_dropdown_minutes_label,
      },
      { time },
    ),
  });
